import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import styled                  from '@emotion/styled';
import { Container, Col, Row } from 'react-grid-system';

import CallToAction
                         from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { Button }        from '@interness/web-core/src/components/elements/Button/Button'
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import Separator         from '@interness/web-core/src/components/structure/Separator/Separator';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import MoreExpander
                         from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

const galleryDescr = [
  'Seien Sie herzlich willkommen in unserem Trauringstudio.',
  'Wählen Sie in Schritt 1: Das Design. Einfarbig, zweifarbig, dreifarbig, mit Fugen und/oder Stufen.',
  'Wählen Sie in Schritt 2: Die Legierung, die Farbe Ihrer Trauringe und die Oberfläche.',
  'Wählen Sie in Schritt 3: Die Form. Unterschiedliche Ringprofile ergeben ein markantes Aussehen.',
  'Wählen Sie in Schritt 4: Die Profilbreite, die Profilstärke und die Weite Ihrer Wunsch-Trauringe.',
  'Wählen Sie in Schritt 5: Die Brillanten und wie diese in Ihre Trauringe eingefasst werden sollen.',
  'Wählen Sie in Schritt 6: Die Gravur, den Gravurtext und die Schriftart. Fertig sind Ihre Trau(m)ringe.',
];

const dispalyItems = [
  {
    title: 'Uhren',
    subtitle: 'Wir schaffen Zufriedenheit',
    descr: 'Klassisch, modern oder trendig. In unserem ausgewälten Uhrenangebot internationaler Herstellern finden Sie Ihr perfektes Modell.',
    image: 'display/watches.jpg',
    path: '/watches'
  },
  {
    title: 'Schmuck',
    subtitle: 'Indiviuell und persönlich',
    descr: 'Schmuck ist Inspiration und Leidenschaft. Aber auch die Natürlichkeit und Authentizität unserer Schmuckkollektionen sind uns besonders wichtig.',
    image: 'display/jewelry.jpg',
    path: '/jewelry'
  },
  {
    title: 'Atelier',
    subtitle: 'Exklusive Schmuckstücke',
    descr: 'Exklusive und individuell gefertigte Schmuckstücke in Zusammenarbeit mit externen Goldschmieden ergänzen unser Sortiment.',
    image: 'display/atelier.jpg',
    path: '/atelier'
  },
  {
    title: 'Trauringe',
    subtitle: 'Lebenstraumringe',
    descr: 'Ein Trauring ist Symbol für die Liebe und soll auch im Alltag die Erinnerung an den schönsten Moment im Leben immer wieder neu erstrahlen lassen.',
    image: 'display/wedding-rings.jpg',
    path: '/wedding_rings'
  }
];

const Display = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const DisplayItem = styled.div`
  width: 22%;
  margin-bottom: 50px;
  position: relative;
  @media (max-width: 960px) {
    width: 48%;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
  > div {
    padding: 10px 0 0 0;
    margin-bottom: 20px;
  }
  img {
    margin-bottom: 0;
  }
  h4 {
    margin: 10px 0 5px 0;
    text-transform: uppercase;
    font-weight: bold;
    text-align: left;
  }
  h5 {
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
  }
  p {
    text-align: justify;
    font-size: 0.9rem;
  }
  a {
    position: absolute;
    bottom: -42px;
  }
`;

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;

const PromoBox = styled.div`
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 3px;
  p {
    font-size: 1.2rem;
  }
`;

function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.edges.map((image) => (
          <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Rotthowe - Uhren & Schmuck</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Willkommen bei Juwelier Rotthowe, Ihrem exklusiven Juweliergeschäft im Herzen von Warendorf. Unser renommiertes Geschäft ist weit über die Grenzen hinaus bekannt für seine exquisite Auswahl an erstklassigem Schmuck, hochwertigen Uhren und zauberhaften Trauringen. Wir legen großen Wert auf exzellenten Service und bieten Ihnen eine Beratung, die nicht nur professionell, sondern auch herzlich und persönlich ist.</p>
            <p>Unsere individuellen Schmuckstücke werden mit größter Sorgfalt und Leidenschaft hergestellt. Wir glauben, dass Schmuck mehr ist als nur ein Accessoire – es ist ein Ausdruck Ihrer Persönlichkeit und Ihrer Geschichte. Deshalb bieten wir maßgeschneiderte Anfertigungen an, um Ihre Visionen in wertvolle Realität umzusetzen. Unsere Experten für Antragsringe helfen Ihnen, den perfekten Ring für diesen besonderen Moment zu finden.</p>
            <p>Bei Juwelier Rotthowe sind wir stolz auf unsere Fachkompetenz im Bereich der Uhrmacherkunst. Unsere Auswahl an Uhren reicht von klassisch elegant bis hin zu modern und innovativ, passend für jeden Geschmack und Anlass. Zudem bieten wir einen umfassenden Reparatur- und Wartungsservice für Uhren und Schmuck an, um die Schönheit und Funktion Ihrer Kostbarkeiten zu erhalten.</p>
            <p>Wir verstehen auch den emotionalen Wert von Erbstücken und bieten daher einen Altgoldankauf-Service an. Ob Sie ein altes Schmuckstück umgestalten oder in etwas Neues investieren möchten, wir stehen Ihnen mit unserer Expertise zur Seite.</p>
            <p>Besuchen Sie Juwelier Rotthowe in Warendorf und erleben Sie die Welt des exquisiten Schmucks und der hochwertigen Uhren. Unser Team freut sich darauf, Sie mit persönlichem Service und Fachwissen zu unterstützen.</p>
            <p>Bis bald, Ihre Familie Rotthowe und Team.</p>
          </span>
        </section>
        <Separator/>
        
        <section style={{ textAlign: 'center' }}>
          <Heading tag={'h2'}>Unser Trauringkonfigurator</Heading>
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>An dieser Stelle möchten wir ihnen gern den
            Trauringkonfigurator von Rauschmayer vorstellen.</p>
          <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>In sechs einfachen Schritten erstellen Sie Ihr
            persönliches und ganz individuelles Trauringpaar.</p>
          <Spacer height={20}/>
          <Carousel>
            {props.data.galleryImages.edges.map((image, index) => (
              <React.Fragment key={index}>
                <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{galleryDescr[index]}</p>
                <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
              </React.Fragment>
            ))}
          </Carousel>
          <Spacer/>
          <PromoBox>
            <p>Und hier geht es direkt zum Trauring-Konfigurator auf die Website von Rauschmayer</p>
            <Button as={Link} target='_blank' to='https://designer.rauschmayer.com/de/profileDesigner#/'>Trauringkonfigurator</Button>
          </PromoBox>
        </section>
        <Separator/>
        <section>
          <Heading tag={'h2'}>Unser virtuelles Schaufenster</Heading>
          <Display>
            {dispalyItems.map(item => (
              <DisplayItem key={item.title}>
                <div>
                  <img src={item.image} alt={item.title}/>
                  <h4>{item.title}</h4>
                  <h5>{item.subtitle}</h5>
                  <p dangerouslySetInnerHTML={{ __html: item.descr }}/>
                </div>
                <Button as={Link} to={item.path}>Mehr erfahren...</Button>
              </DisplayItem>
            ))}
          </Display>
          <Spacer/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>
            Neben unserer Tätigkeit als Juwelier bieten wir Ihnen einen wohl außergewöhnlichen Service für Ihre Uhren.
            Denn wir beginnen da, wo andere bereits gesagt haben, dass sich eine Reparatur nicht lohnt. Somit sind auch
            Liebhaberstücke bei uns herzlich willkommen. Denn Ihre Schmuckstücke sind auch für uns eine gern gesehene
            Herausforderung um unsere Erfahrung und unser Wissen einzubringen.
          </p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Armbanduhren</h3>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Goldschmiedereparaturen</h3>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu fassen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Neuanfertigungen</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Großuhren</h3>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/index"}}, sort: {fields: name}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
    galleryImages: allFile(filter: {relativeDirectory: {eq: "index/konfigurator"}}, sort: {fields: name}) {
      edges {
        node {
          ...GalleryImages
        }
      }
    }
    trauringtag: file(relativePath: {eq: "index/trauringtag.jpg"}) {
      name
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';

import CallToAction  from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import HeaderImage from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';
import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Separator   from '@interness/web-core/src/components/structure/Separator/Separator';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel    from '@interness/web-core/src/components/media/Carousel/Carousel';

function AtelierPage(props) {
  return (
    <>
      <HeaderImage>
        <Img fluid={props.data.headerImage.childImageSharp.fluid}/>
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading><img src='logo.png' alt='Juwelier Rotthowe Logo' /></Heading>
        </section>
        <Spacer />
        <Carousel>
          {props.data.galleryImages.edges.map((image) => (
          <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
          ))}
        </Carousel>
        <Spacer />
        <p>Juwelier Rotthowe, im Herzen der schönen Kreisstadt Warendorf gelegen, ist seit vielen Jahren ein Begriff für aussergewöhnlichen Schmuck der in Zusammenarbeit mit externen Goldschmieden gefertigt wird.</p>
        <p>Mit dieser Zusammenarbeit verfolgen einerseits die perfekte Umsetzung Ihrer Wünsche, und andererseits eine hohe Qualität die sich mit Liebe zum Detail auszeichnet.</p>
        <Separator/>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default AtelierPage;

export const query = graphql`
    query AtelierPageQuery {
        headerImage: file(relativePath: {eq: "headers/atelier.jpg"}) {
            name
            childImageSharp {
                fluid(maxWidth: 1980, maxHeight: 700, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        galleryImages: allFile(filter: {relativeDirectory: {eq: "atelier"}}, sort: {fields: name}) {
            edges {
                node {
                    ...GalleryImages
                }
            }
        }
    }
`;